import api from "../config/api.js";

export async function logoutUser() {
  try {
    return api.get("/auth/logout");
  } catch (error) {
    console.log("Logout Error:", error);
  }
}

export async function userAuthenticated() {
  try {
    await api.get("/auth/user").then(({ data }) => {
      if (data === false) {
        return false;
      }
      setLoggedInUser(data.dbUserDetails.username);
      const userDetails = data.dbUserDetails;
      userDetails.emailVerified = data.emailVerified;
      userDetails.hasAcceptedLatestTerms = data.hasAcceptedLatestTerms;
      userDetails.hasAuthLinkId = Boolean(data.authLinkId);
      setLoggedInUserDetails(userDetails);
      return true;
    });
  } catch (error) {
    console.log("Error checking for authenticated user");
    throw error;
  }
}

export function getLoggedInUser() {
  return localStorage.getItem("loggedInUser");
}

export function setLoggedInUser(user) {
  user ? localStorage.setItem("loggedInUser", user) : localStorage.removeItem("loggedInUser");
}

export function getLoggedInUserDetails() {
  return localStorage.getItem("loggedInUserDetails");
}

export function setLoggedInUserDetails(data) {
  Object.keys(data).length ? localStorage.setItem("loggedInUserDetails", JSON.stringify(data)) : localStorage.removeItem("loggedInUserDetails");
}
