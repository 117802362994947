import React, { Fragment } from 'react';
import Student from "./Student.js";
import { List } from '@material-ui/core';
import { Alert } from "@material-ui/lab";

const StudentsList = ({ students, setStudents, setNotificationDetails }) => {
    return (
        <Fragment>
            {students.length === 0 && <Alert severity="info">Add a student</Alert>}
            <List>
                {students.map((student, i) => <Student key={i} data={student} students={students} setStudents={setStudents} setNotificationDetails={setNotificationDetails} />)}
            </List>
        </Fragment>
    )
}

export default StudentsList;