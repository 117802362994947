import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, InputLabel, FormControlLabel, Switch } from '@material-ui/core';
import StyledButton from '../common/StyledButton.js';
import { CirclePicker } from 'react-color';
import { useTreatments, SplitContext } from "@splitsoftware/splitio-react";
import { nameIsValid, emailIsValid } from '../../utils/FieldValidation.js';
import Spinner from '../common/Spinner.js';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
    },
    colorLabel: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(2),
    },
    allowStudentLogin: {
        display: "flex",
        flexDirection: "column",
        paddingTop: theme.spacing(2),
    }
}));

const StudentModal = ({ isOpen, cancelFunction, submitFunction, name, setName, color, setColor, email, setEmail, allowLogin, setAllowLogin, showSpinner = false }) => {

    const classes = useStyles();
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [nameErrorText, setNameErrorText] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState(false);
    const { isReady } = useContext(SplitContext);
    const treatmentName = {
      studentLogin: "student_login"
    };
    const treatment = useTreatments([treatmentName.studentLogin]);

    const onNameChange = (event) => {
        setName(event.target.value);
    }

    const onColorChange = (color) => {
        setColor(color.hex);
    }

    const onEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const onAllowLoginChange = () => {
        if (allowLogin) {
            setAllowLogin(false);
            setEmail("");
        } else {
            setAllowLogin(true);
        }
    }

    const validateStudentDetails = () => {
        let isValid = true;
        if (name === "") {
          setNameErrorText("Name is required");
          setNameError(true);
          isValid = false;
        } else if (!nameIsValid(name)) {
          setNameErrorText("Name must be valid");
          setNameError(true);
          isValid = false;
        } else {
          setNameErrorText("");
          setNameError(false);
        };
    
        if (allowLogin) {
            if (email === "") {
                setEmailErrorText("Email is required");
                setEmailError(true);
                isValid = false;
              } else if (!emailIsValid(email)) { 
                setEmailErrorText("Email must be valid");
                setEmailError(true);
                isValid = false;
              } else {
                setEmailErrorText("");
                setEmailError(false);
              };
        };
    
        return isValid;
    }

    const onSubmit = (event) => {
        event.preventDefault();
        if (validateStudentDetails()) {
            submitFunction(event);
        }
    }

    const colorList = [ //Reference: https://palx.jxnblk.com/ (based on #ca253c i.e. #CA4558 with increased saturation)
        "#d96375",
        "#d98e65",
        "#cbb32a",
        "#a3d34b",
        "#78da66",
        "#67da8f",
        "#5cd7c5",
        "#7abbdf",
        "#8f9ce4",
        "#ae91e4",
        "#d282e1",
        "#e080be"
    ]

    return (
        <Dialog open={isOpen}>
            <DialogTitle>Student</DialogTitle>
            <DialogContent>
                <form className={classes.root} id="form" onSubmit={onSubmit} autoComplete="off">
                    <TextField name="name" label="Name" value={name} onChange={onNameChange} error={nameError} helperText={nameErrorText} />
                    { isReady && treatment[treatmentName.studentLogin].treatment === "on" //Feature flag
                        ? <div className={classes.allowStudentLogin}>
                            <FormControlLabel 
                                control={<Switch 
                                    checked={allowLogin}
                                    onChange={onAllowLoginChange}
                                />} 
                                label="Allow Login" 
                            />
                            {allowLogin && <TextField name="email" label="Email" value={email} onChange={onEmailChange} error={emailError} helperText={emailErrorText} />}
                        </div>
                        : null
                    }
                    <InputLabel className={classes.colorLabel}>Colour</InputLabel>
                    <CirclePicker color={color} colors={colorList} onChangeComplete={onColorChange}/>
                </form>
            </DialogContent>
            <DialogActions>
                <StyledButton onClick={cancelFunction}>
                    Cancel
                </StyledButton>
                <StyledButton form="form" type="submit">
                    Done
                </StyledButton>
            </DialogActions>
            <Spinner open={showSpinner} />
        </Dialog>
    )
}

export default StudentModal;